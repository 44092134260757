
<template>
    <Layout>
        <PageHeader :title="title" :items="[]" />
    
      <LoadingVue v-if="load" />
        <div class="row g-2" v-else>
            <div class="col-md-8 mx-auto">
                  <Notification />
    <div class="row">
    
                <div class="col-md-4">
                    <div>
    
                        <div class="row">
                           <div class="col-12" v-for="(item, ) in items" :key="item.id">
    
    
    
                                    <div  class="card" :class="{active: addclass(item)}" @click="selectedelct(item)">
    
                                        <div class="card-body p-1">
                                            <div class="form-check custom-option custom-option-basic checked">
                                                <label class="form-check-label custom-option-content" :for="item.code">
                      
                        <img :src="link+item.image" alt="" class="rounded-circle avatar-sm  me-2">
                        <span class="custom-option-body">
                          <strong class="fs-5">{{item.name}}</strong>
                        </span>
                      </label>
                                            </div>
                                        </div>
                                    </div>
    
                                </div>
                        </div>
    
    
                        <!--end row-->
    
                        <!--end offcanvas-->
                    </div>
                </div>

                
                <div class="col-md-8">
     <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">Education</h4>
       
          </div>
          <!-- end card header -->
          <div class="card-body">
       
            <div class="live-preview" >
<div class="text-center"  v-if="loadprice">
 <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
  <span class="visually-hidden">Loading...</span>
</div>
           </div>
           
              <form action="javascript:void(0);" v-else-if="(viewform == 'waec') && pricedata">
           
               <div class="mb-3">
                  <label for="employeeUrl" class="form-label"
                    >How Many Pin You Want To Purchase</label
                  >
                   <div class="input-step full-width step-primary">
                        <button type="button" @click="calcsub()" class="minus">–</button>
                        <input
                          type="number"
                          class="product-quantity"
                   
                          v-model="numberpin"
                          min="1"
                          max="10"
                          readonly
                        />
                        <button type="button" @click="calcadd()" class="plus">+</button>
                      </div>
                </div>
                 <div class="mb-3">
                  <label for="amount" class="form-label"
                    >Amount</label
                  >
                  <input
                    type="url"
                    class="form-control"
                    id="amount"
                    v-model="mainamount"
                    readonly
                    placeholder="Amount"
                  />
                </div>
              
                <div class="text-end">
                  <button type="submit" class="btn btn-primary" :disabled="subload" @click="submit">
                   <div class="spinner-border spinner-border-sm" role="status" v-if="subload" > 
  <span class="sr-only">Loading...</span>
</div>
                Continue
                  </button>
                </div>
              </form>

 <form action="javascript:void(0);" v-else-if="(viewform == 'jamb') && pricedata">
                <div class="mb-3">
                  <label for="employeeName" class="form-label"
                    >Employee Name</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="employeeName"
                    placeholder="Enter emploree name"
                  />
                </div>
                <div class="mb-3">
                  <label for="employeeUrl" class="form-label"
                    >Employee Department URL</label
                  >
                  <input
                    type="url"
                    class="form-control"
                    id="employeeUrl"
                    placeholder="Enter emploree url"
                  />
                </div>
              
                <div class="text-end">
                  <button type="submit" class="btn btn-primary">
                    Add Leave
                  </button>
                </div>
              </form>

            </div>
          
          </div>








        </div>
      </div>
   
    </div>
                </div>
                </div>
            </div>
            <!-- end col -->
        </div>
        <!--end row-->
    
    
    </Layout>
</template>
<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
import axios from 'axios';
import Swal from "sweetalert2";
import LoadingVue from "./components/Loading.vue";

export default {
    page: {
        title: "Education",
        meta: [{ name: "description", content: appConfig.description }],
    },
    data() {
        return {
            title: "Education",
            items: [],
             link: process.env.VUE_APP_URL,
             viewform: null,
             mainamount: null,
             subload:false,
             numberpin: 1,
             pricedata: null,
            selecteditem: null,
            load: false,
            loadprice: false,
        
            more: false,
        };
    },
    name: "Widgets",
    components: {
        Layout,
        PageHeader,
        LoadingVue,
    },
    created() {
        this.getitems();
    },



  watch: {
    selecteditem: function(val){
     
// "/confirm/education/price"
      if(val != null){
   this.viewform = val.code;

   this.getprice();
       




      }
    },



    numberpin: function(val) {


this.mainamount = this.pricedata[0].amount * val;

      




    },
    

    




  },
    methods: {



      submit() {

        if (this.viewform == 'waec') {

          this.processtransaction();
          
        } 





      },

                  async processtransaction() {
       this.subload = true;

          await axios.post(process.env.VUE_APP_MAIN_URL + "/process/education", { type: this.viewform, numberofpin: this.numberpin,  amount: this.mainamount }).catch((error) => {

              this.subload = false;

            if (error.response.status == 420) {
               Swal.fire("Error!", error.response.data.data, "error");
            }else {
                  Swal.fire("Error!", "Please make sure you have active internet or fields are correct", "error");
            }

               
                throw error;

            }).then((response) => {
                this.subload = false;
                 this.$router.push({name: "transaction", params: { hash: response.data.data.data.hash}});

            })

      },


      calcadd(){


if (this.numberpin < 10) {
      this.numberpin = this.numberpin + 1;
}
 
     

      

    

      },
        calcsub(){

 

         if(this.numberpin > 1){
         this.numberpin = this.numberpin - 1;
               console.log(this.numberpin);

        }

      },









             async getprice() {
                 this.loadprice = true;
            await axios.post(process.env.VUE_APP_MAIN_URL + "/confirm/education/price", {type: this.viewform}).catch((error) => {

              if (error.response.status == 420) {
                   Swal.fire("Error!", error.response.data.data, "error");
              } else {
                   Swal.fire("Error!", "Please Check Your Internet Connection", "error");
              }

    this.loadprice = false;
             
                throw error;

            }).then((response) => {
this.loadprice = false;
                if (response.data.status == true) {

                    this.pricedata = response.data.data.data;
                    this.mainamount = this.pricedata[0].amount;


                }

            })
        },




               selectedelct(item) {
            this.selecteditem = item;
        },
        addclass(item) {


            return item == this.selecteditem;
        },
             async getitems() {
                 this.load = true;
            await axios.get(process.env.VUE_APP_MAIN_URL + "/get/education").catch((error) => {

    this.load = false;
                Swal.fire("Error!", "Please Check Your Internet Connection", "error");
                throw error;

            }).then((response) => {
this.load = false;
                if (response.data.status == true) {

                    this.items = response.data.data.data;


                }

            })
        },
    },
};
</script>

<style scoped>
.active  {
  border: 2px solid green;
}
</style>